import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import item from "../assets/review.png"
import item1 from "../assets/review1.png"
import { Grid, Button, Rating } from '@mui/material';
import "../Components/YourStyles.css"
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({

  dots: {
    '& .slick-dots li.slick-active button::before': {
      color: '#fff',
      opacity: 1,
    },
    '& .slick-dots li button::before': {
      color: '#636e72',
      fontSize: '9px',
      opacity: 1,
    },
    '& .slick-dots li ': {
      margin: '-10px -1px',
    },
    marginBottom: 10,
  },
});

export default function Review() {
  const matches = useMediaQuery("(max-width:700px)");
  const matchesB = useMediaQuery("(max-width:500px)");
  const matchesA = useMediaQuery("(max-width:1400px)");
  const sliderRef = useRef(null);
  const settings = {
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    slidesToShow: matches ? 1 : matchesA ? 3 : 5,
    arrows: false,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: 'true',
    autospeed: 1,
  };


  var useStyle = useStyles()
  var data = [item,item1,item,item1,item,item1,item]

  const handleImageClick = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  const ShowSlider = () => {
    return data.map((item, index) => {
      return (<Grid key={index} sx={{ width: '100%', display: 'block', flexDirection: 'column', mb: 10 }}>
        <Grid onClick={() => handleImageClick(index)} style={{ height: '300px', borderRadius: '24px', display: 'flex', justifyContent: 'center', flexDirection: 'row', textAlign: 'center', margin: '15px', borderrRadius: '10px' }}>
        <img
           src={item} style={{ width: '98%', margin: 4, borderRadius: 10, objectFit: 'cover' }} 
          />
        </Grid>
        <Grid sx={{ display: 'flex', flexDirection: 'column', marginTop: matchesB ? '-28%' : matches ? '-22%' : '2.5%', color: '#fff', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', height: matchesB ? 250 : matches ? 300 : "auto" }}>
          <Grid sx={{ fontSize: matches ? '16px' : '1vw', fontWeight: 700, width: '80%' }}>
            Ankit Rajppot
          </Grid>
          <Grid><Rating style={{ marginLeft: 'auto', marginTop: '2%', display: 'flex' }}
            size="small"
            color="green"
            name="simple-controlled"
            value={5}
          /></Grid>
          <Grid sx={{ fontSize: matches ? '11px' : '.6vw', fontWeight: 400, marginTop: '1%', width: '80%' }}>
          The food was excellent! I had the steak, and it was cooked perfectly.
          </Grid>
         
        </Grid>
      </Grid>)
    })
  }

  return (<Grid style={{ width: '100%', display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '12%',color:'#fff' ,}}>
 <Grid sx={{fontSize:'52px',fontWeight:700,letterSpacing:'-2.4%'}}>
             REVIEWS
             </Grid>
             <Grid sx={{ fontSize:'16px',marginTop:'1.6%',width:'60%',lineHeight:'26px',textAlign:'center',}}>
             Review ipsum dolor sit amet, consectetur adipiscing elit. Nam vel placerat lorem, et lacinia sapien. Sed nec sodales felis. Fusce tincidunt magna nibh, ac maximus eros vulputate non. Duis nec lorem mi.
            </Grid>
          
    <Grid sx={{ width: '100%', display: 'block !important', height: 'fit-content !important',marginTop:"4%" }} className={matches?'':useStyle.dots}>
      <Slider ref={sliderRef} {...settings} >
        {ShowSlider()}
      </Slider>
    </Grid>
  
  </Grid>)
}
