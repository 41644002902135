import { Button, Grid, InputBase } from "@mui/material";
import msg from "../assets/msg.png"
import contact from "../assets/contact.png"
import insta from "../assets/insta.png"
import whatsapp from "../assets/whatsapp.png"
import fb from "../assets/fb.png"
import twitter from "../assets/twitter.png"
import { GTranslate } from "@mui/icons-material";
import useMediaQuery from '@mui/material/useMediaQuery';
export default function Footer()
{

  const matches = useMediaQuery("(max-width:1000px)");


    return(  <Grid sx={{width:'100%',display:'flex',marginTop:'11%',justifyContent:'center',alignItems:'center',color:'#fff'}}>
        <Grid sx={{width:'70%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',marginBottom:'4%'}}>
         <Grid sx={{fontSize:matches?'38px':'52px',fontWeight:700,letterSpacing:'-2.4%'}}>
         CONTACT US
         </Grid>
         <Grid sx={{ fontSize:'16px',marginTop:'1.6%',width:matches?'100%':'60%',lineHeight:'26px',textAlign:'center',}}>
         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vel placerat lorem, et lacinia sapien.
        </Grid>
        <Grid sx={{width:'90%',marginTop:'6%',display:'flex',flexDirection:matches?"column":'row'}}>
         <Grid sx={{width:matches?'100%':'50%'}}>
           <Grid sx={{fontSize:matches?'16px':'20px',fontWeight:700,}}>
            CONTACT INFORMATION
           </Grid>
           <Grid sx={{display:'flex',alignItems:'center',marginTop:'6%',gap:1}}>
          <Grid> <img src={contact} width={20}></img></Grid>
          <Grid sx={{fontSize:'16px',fontWeight:500,color:'#7f8c8d',marginTop:'-2%'}}>Molly_paradise</Grid>
           </Grid>
           <Grid sx={{display:'flex',alignItems:'center',marginTop:'6%',gap:1}}>
          {/* <Grid> <img src={call} width={20}></img></Grid> */}
          <Grid sx={{fontSize:'16px',fontWeight:500,color:'#7f8c8d',marginTop:'-2%'}}>+38(050)000-00-00</Grid>
           </Grid>
           <Grid sx={{display:'flex',alignItems:'center',marginTop:'6%',gap:1}}>
          <Grid> <img src={msg} width={20}></img></Grid>
          <Grid sx={{fontSize:'16px',fontWeight:500,color:'#7f8c8d',marginTop:'-2%'}}>mollyparadise@gmail.com</Grid>
           </Grid>
           <Grid sx={{display:'flex',alignItems:'center',marginTop:'6%',gap:2}}>
          <Grid sx={{fontSize:'16px',fontWeight:500,color:'#7f8c8d',marginTop:'-2%'}}>Working time at 09:00am - 06:00pm</Grid>
           </Grid>
           <Grid sx={{fontSize:'18px',fontWeight:600,marginTop:'8%'}}>
           Social media
           </Grid>
           <Grid sx={{display:'flex',alignItems:'center',marginTop:'5%',gap:2}}>
            <Grid><img src={insta} width={24}></img></Grid>
            <Grid><img src={whatsapp} width={24}></img></Grid>
            <Grid><img src={fb} width={24}></img></Grid>
            <Grid><img src={twitter} width={24}></img></Grid>
           </Grid>
           <Grid sx={{fontSize:'10px',fontWeight:400,marginTop:'6%',color:'#7f8c8d'}}>
           © 2018 MollyParadise.
           </Grid>

         </Grid>
         <Grid sx={{width:matches?'100%':'50%',marginTop:matches?'10%':''}}>
         <Grid sx={{fontSize:matches?'16px':'20px',fontWeight:700,}}>
         SEND ONLINE APPLICATION
           </Grid>
           <Grid sx={{display:'flex',alignItems:'center',gap:2,marginTop:'4%'}}>
           <Grid
                  sx={{
                    border: ".8px solid #7f8c8d",
                    borderRadius: "30px",
                    color: "#000",
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    height:matches?'35px': "45px",
                    width: "100%",
                  }}
                >
                  <InputBase
                    style={{ color: "#7f8c8d",fontSize:'14px'  }}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Enter Name"
                  />
                </Grid>
                <Grid
                  sx={{
                    border: ".8px solid #7f8c8d",
                    borderRadius: "30px",
                    color: "#000",
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    height: matches?'35px':"45px",
                    width: "100%",
                  }}
                >
                  <InputBase
                    style={{ color: "#7f8c8d",fontSize:'14px' }}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Enter Email"
                  />
                </Grid>
                </Grid>
                <Grid sx={{marginTop:'4%'}}><textarea style={{height:180,borderColor:'#7f8c8d',border:'.8px solid #7f8c8d',borderRadius:'10px',width:'100%',background:'transparent',marginTop:'1%'}} placeholder="Fell Free To Enquire anything here."></textarea></Grid>
                <Button
                sx={{
                 border:'1px solid #7f8c8d',
                  height: matches?'35px':42,
                  width:matches?'220px':'16.5vw',
                  color: "#7f8c8d",
                  fontWeight: "bold",
                  justifyContent: "center",
                  textTransform: "none",
                  padding: 2.6,
                  display: "flex",
                  borderRadius: 20,
                  fontSize: "14px",
                  lineHeight:'22px',
                  fontWeight: 600,
                  alignItems:'center',
                  marginTop:'4%',
                  marginLeft:'auto'
                }}
              >
              Send
              </Button>
         </Grid>
        </Grid>
      
</Grid>
</Grid>)
}