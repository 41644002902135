import {Routes,Route,BrowserRouter as Router} from 'react-router-dom'
import Home from './Components/Screens.js/Home';
import Footer from './Components/Footer';
function App() {
  return (
    <div>
       <Router>
        <Routes>
          <Route element={<Home/>} path="/"/>
          <Route element={<Footer/>} path="/footer"/>
        </Routes>
       </Router>
    </div>
  );
}

export default App;
