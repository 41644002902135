import { Button, Divider, Grid } from "@mui/material";
import food from "../assets/container.png"
import containers from "../assets/ankit.jpg"
import Abouttofu from "../assets/ankit2.jpg"
import restaurent from "../assets/restorent.jpg"
import { useState } from "react";
import item0 from "../assets/item.png"
import item1 from "../assets/item1.png"
import item2 from "../assets/item2.png"
import safe from "../assets/safe.png"
import safe1 from "../assets/safe1.png"
import safe2 from "../assets/safe2.png"
import foods from "../assets/food.png"
import dish from "../assets/dish.png"
import Birth from "../assets/birth.png"
import SliderForReview from "./SliderForItem";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStyles } from "./Screens.js/HoverdCss";
import logo from "../assets/logo2.png"
import menu from "../assets/menu.png"
import { useNavigate } from "react-router-dom";

export default function Container()
{

  const matches = useMediaQuery("(max-width:1000px)");
  const classes = useStyles()

  var navigate = useNavigate();

  const [category, setCategory] = useState(true);
  const [coffee, setCoffee] = useState(false);
  const [snack, setSnack] = useState(false);
  const [burgur, setBurgur] = useState(false);
  const [chines, setChines] = useState(false);
  const [south, setSouth] = useState(false);

  

  const handleCatygory = () => {
    setCategory(true);
    setCoffee(false);
    setSnack(false);
    setBurgur(false);
    setChines(false);
    setSouth(false);
  };

  const handleCoffee = () => {
    setCoffee(true);
    setSnack(false);
    setCategory(false);
    setBurgur(false);
    setChines(false);
    setSouth(false);
  };

  const handleSnack = () => {
    setCoffee(false);
    setSnack(true);
    setBurgur(false);
    setChines(false);
    setCategory(false);
    setSouth(false);
  };

  const handleBurgur = () => {
    setCoffee(false);
    setSnack(false);
    setBurgur(true);
    setChines(false);
    setCategory(false);
    setSouth(false);
  };

  const handleChines = () => {
    setCoffee(false);
    setSnack(false);
    setCategory(false);
    setBurgur(false);
    setChines(true);
    setSouth(false);
  };

  const handleSouth = () => {
    setCoffee(false);
    setSnack(false);
    setCategory(false);
    setBurgur(false);
    setChines(false);
    setSouth(true);
  };


  const [home, setHome] = useState(false);
  const [about, setAbout] = useState(false);
  const [menus, setMenus] = useState(false);
  const [gallery, setGallery] = useState(false);
  const [chief, setChief] = useState(false);
  const [item, setItem] = useState(false);
  const [contact, setContact] = useState(false);


  const handleHome = () => {
    setHome(!home);
    setTimeout(() => {
      if (home === false) {
        var section = document.getElementById("about");
        if (section) {
          section.scrollIntoView({ behavior: "smooth", position: "fixed" });
        }
      }
    }, 100);
  }

  
  const handleOne = () => {
    setAbout(!about);
    setTimeout(() => {
      if (about === false) {
        var section = document.getElementById("about");
        if (section) {
          section.scrollIntoView({ behavior: "smooth", position: "fixed" });
        }
      }
    }, 100);
  }

  
  
  const handleTwo = () => {
    setMenus(!menus);
    setTimeout(() => {
      if (menus === false) {
        var section = document.getElementById("menus");
        if (section) {
          section.scrollIntoView({ behavior: "smooth", position: "fixed" });
        }
      }
    }, 100);
  }

  const handleThree = () => {
    setGallery(!gallery);
    setTimeout(() => {
      if (gallery === false) {
        var section = document.getElementById("gallery");
        if (section) {
          section.scrollIntoView({ behavior: "smooth", position: "fixed" });
        }
      }
    }, 100);
  }

  
  const handleFour = () => {
    setChief(!chief);
    setTimeout(() => {
      if (chief === false) {
        var section = document.getElementById("chief");
        if (section) {
          section.scrollIntoView({ behavior: "smooth", position: "fixed" });
        }
      }
    }, 100);
  }

  
  const handleFive = () => {
    setItem(!item);
    setTimeout(() => {
      if (item === false) {
        var section = document.getElementById("item");
        if (section) {
          section.scrollIntoView({ behavior: "smooth", position: "fixed" });
        }
      }
    }, 100);
  }

  
  const handleSix = () => {
    setContact(!contact);
    setTimeout(() => {
      if (contact === false) {
        var section = document.getElementById("contact");
        if (section) {
          section.scrollIntoView({ behavior: "smooth", position: "fixed" });
        }
      }
    }, 100);
  }


    return(<Grid sx={{width:'100%',fontFamily:'Muli, sans-serif',background:'#000',color:'#fff'}}>

        <Grid sx={{width:'100%',fontFamily:'Muli, sans-serif',color:'#fff',position:"sticky",top:0,left:0,zIndex:1000}}>
          <Grid sx={{width:'100%',height:'100px',background: "linear-gradient(rgba(30, 50, 80, 0.1), #0A0A0A)",display:'flex',alignItems:'center',justifyContent:'center'}}>
              <Grid sx={{width:'92%',height:'120px',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                <Grid sx={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'1%',cursor:'pointer'}}>
                 <Grid sx={{display:'flex',alignItems:'center',fontFamily: "cursive"}}>
                      <Grid sx={{fontSize:'16px',fontWeight:600,lineHeight:'22px',letterSpacing:"-2.4%",color:"red"}}>
                        OJASWI 
                      </Grid>
                      <Grid sx={{fontSize:'16px',fontWeight:600,lineHeight:'22px',letterSpacing:"-2.4%",marginLeft:'2%',color:"#6ab04c"}}>
                        FOODS 
                      </Grid>
                 </Grid>
                 <Grid sx={{marginTop:"3%",width:'80%'}}>
                 <Divider
            style={{
              backgroundColor: "#44bd32",
              height:'1px'
            }}
          />
                 </Grid>
                 <Grid sx={{marginTop:'2%'}}>
                  <img src={logo} width={matches?40:50}></img>
                 </Grid>
                 </Grid>

                 {matches? <></> :<Grid sx={{display:'flex',alignItems:'center',gap:5, fontWeight: 300,fontSize: "15px",fontFamily:'Muli, sans-serif'}}>
                  <Grid  sx={{cursor:'pointer'}} className={classes.button}>
                   HOME
                  </Grid>
                  <Grid onClick={handleOne} sx={{cursor:'pointer'}} className={classes.button}>
                    ABOUT
                  </Grid>
                  <Grid onClick={handleTwo} sx={{cursor:'pointer'}} className={classes.button}>
                    MENU
                  </Grid >
                  <Grid onClick={handleThree} sx={{cursor:'pointer'}} className={classes.button}>
                   GALLERY
                  </Grid>
                  <Grid onClick={handleFour} sx={{cursor:'pointer'}} className={classes.button}>
                    CHIEFS
                  </Grid>
                  <Grid onClick={handleFive} sx={{cursor:'pointer'}} className={classes.button}>
                   ITEMS
                  </Grid>
                  <Grid onClick={handleSix} sx={{cursor:'pointer'}} className={classes.button}>
                    CONTACT
                  </Grid>
                 </Grid>}
                  {matches?<><Grid>
                  <img src={menu} width={40}></img>
                  </Grid></>:<></>}
              </Grid>
          </Grid>
    </Grid>

           <Grid sx={{width:'94%',display:'flex',marginLeft:'5%',marginTop:'7%',marginLeft:"auto",flexDirection:matches?"column":'row'}}>
            <Grid sx={{width:matches?'90%':'50%',display:'flex'}}>
              <Grid>
                 <Grid sx={{fontSize:matches?'45px':'72px',fontWeight:matches?800:900,}}>
                OJASWI FOODS
                 </Grid>
                 <Grid sx={{ fontSize:'16px',marginTop:'3%',width:matches?'90%':'60%',lineHeight:'30px'}}>
                 Ojaswi Tofu, made with cleanliness, keeps you and your family healthy. So, include Ojaswi Tofu in your diet today! Make Ojaswi Tofu a part of your daily diet and enjoy the well-being it brings to you and your loved ones.
                 </Grid>
                 <Grid sx={{marginTop:'5%',fontFamily: "Montserrat",}}>
                 <Button  className={classes.button1}
                sx={{ 
                 border:'1px solid #7f8c8d',
                  height: 42,
                  width:matches?'200px':'16.5vw',
                  color: "#7f8c8d",
                  fontWeight: "bold",
                  justifyContent: "center",
                  textTransform: "none",
                  padding: 2.6,
                  display: "flex",
                  borderRadius: 20,
                  fontSize: "14px",
                  lineHeight:'22px',
                  fontWeight: 500,
                  alignItems:'center',
                }}
              >
              RESERVE TABLE
              </Button>
              </Grid>
              </Grid>
            </Grid>
            <Grid sx={{width:matches?'90%':"50%",display:'flex',alignItems:'center',marginTop:matches?'6%':''}}>
            <img src={containers} style={{width:'100%'}}></img>
            </Grid>
           </Grid>









           <div id="about"></div>

           <Grid sx={{width:'100%',display:'flex',marginTop:matches?'15%':'9%',justifyContent:'center',alignItems:'center'}}>
            <Grid sx={{display:'flex',width:'88%',justifyContent:'center',alignItems:'center',flexDirection:matches?"column":'row'}}>
              <Grid sx={{width:matches?'90%':'50%'}}> 

            <Grid sx={{fontSize:matches?'45px':'62px',fontWeight:800,letterSpacing:'-2.4%'}}>
            ABOUT US
            </Grid>   
            <Grid sx={{ fontSize:'16px',marginTop:'2%',width:matches?'90%':'80%',lineHeight:'30px'}}>
            At Ojaswi Soy Food, we are dedicated to bringing the finest, healthiest, and most nutritious soy-based products to your table. Our journey began with a simple vision: to provide families with wholesome, plant-based foods that contribute to a balanced and healthy lifestyle.
            </Grid>
            <Grid sx={{ fontSize:'16px',marginTop:'1.5%',width:matches?'90%':'80%',lineHeight:'30px'}}>
            Ojaswi Tofu, our flagship product, embodies our commitment to quality and cleanliness. Every block of tofu is crafted with meticulous care, ensuring that it meets the highest standards of purity and nutrition. We believe that what you eat should not only taste good but also nourish your body and support your well-being.
            </Grid>
            <Grid sx={{ fontSize:'16px',marginTop:'1.5%',width:matches?'90%':'80%',lineHeight:'30px'}}>
            Join us on our mission to promote health, wellness, and sustainability through the power of soy.
            </Grid>
            </Grid>
            <Grid sx={{width:matches?'90%':'50%',marginTop:matches?'6%':''}}>
          <img src={Abouttofu} style={{width:'100%'}}></img>
            </Grid>
            </Grid>
           </Grid>











           <Grid sx={{width:'100%',display:'flex',marginTop:'8%',justifyContent:'center',alignItems:'center'}}>
            <Grid sx={{width:'88%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
            <div id="menus"></div>
             <Grid sx={{fontSize:matches?'46px':'52px',fontWeight:700,letterSpacing:'-2.4%',marginTop:'3%'}}>
             MENU
             </Grid>
             <Grid sx={{ fontSize:'16px',marginTop:'1.6%',width:matches?'80%':'45%',lineHeight:'26px',textAlign:'center',}}>
             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tincidunt ex at sem interdum cursus. Aliquam interdum orci eget convallis laoreet.
            </Grid>
            
            <Grid sx={{display:'flex',width:'80%',color:'#ecf0f1',marginTop:'2%',justifyContent:matches?"center":'space-between',flexDirection:matches?"column":'row'}}>
             <Grid sx={{width:matches?'100%':'45%'}}>
              <Grid sx={{fontSize:'18px',fontWeight:600,lineHeight:'30px'}}>
                HOT
              </Grid>
               <Grid sx={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'4%'}}>
                <Grid sx={{fontSize:'16px'}}>
                Lorem ipsum dolor
                </Grid>
                <Grid sx={{fontSize:'52px',fontWeight:500}}>
                  20$
                 </Grid>
               </Grid>
               <Grid sx={{marginTop:"4%",width:'100%'}}>
                 <Divider
            style={{
              backgroundColor: "#7f8c8d",
              height:'2px'
            }}
          />
                 </Grid>

                 <Grid sx={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'4%'}}>
                <Grid sx={{fontSize:'16px'}}>
                Lorem ipsum dolor
                </Grid>
                <Grid sx={{fontSize:'52px',fontWeight:500}}>
                  20$
                 </Grid>
               </Grid>
               <Grid sx={{marginTop:"4%",width:'100%'}}>
                 <Divider
            style={{
              backgroundColor: "#7f8c8d",
              height:'2px'
            }}
          />
                 </Grid>

                 <Grid sx={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'4%'}}>
                <Grid sx={{fontSize:'16px'}}>
                Lorem ipsum dolor
                </Grid>
                <Grid sx={{fontSize:'52px',fontWeight:500}}>
                  20$
                 </Grid>
               </Grid>
               <Grid sx={{marginTop:"4%",width:'100%'}}>
                 <Divider
            style={{
              backgroundColor: "#7f8c8d",
              height:'2px'
            }}
          />
                 </Grid>
             </Grid>
             <Grid sx={{width:matches?'100%':'45%',marginTop:matches?'10%':''}}>
              <Grid sx={{fontSize:'18px',fontWeight:600,lineHeight:'30px'}}>
                HOT
              </Grid>
               <Grid sx={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'4%'}}>
                <Grid sx={{fontSize:'16px'}}>
                Lorem ipsum dolor
                </Grid>
                <Grid sx={{fontSize:'52px',fontWeight:500}}>
                  20$
                 </Grid>
               </Grid>
               <Grid sx={{marginTop:"4%",width:'100%'}}>
                 <Divider
            style={{
              backgroundColor: "#7f8c8d",
              height:'2px'
            }}
          />
                 </Grid>

                 <Grid sx={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'4%'}}>
                <Grid sx={{fontSize:'16px'}}>
                Lorem ipsum dolor
                </Grid>
                <Grid sx={{fontSize:'52px',fontWeight:500}}>
                  20$
                 </Grid>
               </Grid>
               <Grid sx={{marginTop:"4%",width:'100%'}}>
                 <Divider
            style={{
              backgroundColor: "#7f8c8d",
              height:'2px'
            }}
          />
                 </Grid>

                 <Grid sx={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'4%'}}>
                <Grid sx={{fontSize:'16px'}}>
                Lorem ipsum dolor
                </Grid>
                <Grid sx={{fontSize:'52px',fontWeight:500}}>
                  20$
                 </Grid>
               </Grid>
               <Grid sx={{marginTop:"4%",width:'100%'}}>
                 <Divider
            style={{
              backgroundColor: "#7f8c8d",
              height:'2px'
            }}
          />
                 </Grid>
             </Grid>
            </Grid>

           <Grid sx={{marginTop:"4%"}}>
            <Button  className={classes.button1}
                sx={{
                 border:'1px solid #7f8c8d',
                  height: 42,
                  width:matches?'250px':'16.5vw',
                  color: "#7f8c8d",
                  fontWeight: "bold",
                  justifyContent: "center",
                  textTransform: "none",
                  padding: 2.6,
                  display: "flex",
                  borderRadius: 20,
                  fontSize: "14px",
                  lineHeight:'22px',
                  fontWeight: 500,
                  alignItems:'center',
                }}
              >
              RESERVE TABLE
              </Button>
              </Grid>

            </Grid>
            </Grid>












       

            <Grid sx={{width:'100%',display:'flex',marginTop:'6%',justifyContent:'center',alignItems:'center'}}>
            <Grid sx={{width:'70%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
            <div id="gallery"></div>
             <Grid sx={{fontSize:matches?'46px':'52px',fontWeight:700,letterSpacing:'-2.4%',marginTop:'4%'}}>
             GALERY
             </Grid>
             <Grid sx={{ fontSize:'16px',marginTop:'1.6%',width:matches?'100%':'65%',lineHeight:'26px',textAlign:'center',}}>
             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tincidunt ex at sem interdum cursus. Aliquam interdum orci eget convallis laoreet.
            </Grid>
            <Grid sx={{width:'100%',marginTop:'2%'}}>
            {category ? (
              <>
              <Grid sx={{width:'100%',display:'flex',justifyContent:matches?'center':'space-between',alignItems:'center',flexDirection:matches?"column":'row',gap:matches?6:'',marginTop:'4%'}}>
                <img src={item0} style={{width:matches?'80%':'30%'}}></img>
                <img src={item1} style={{width:matches?'80%':'30%'}}></img>
                <img src={item2} style={{width:matches?'80%':'30%'}}></img>
              </Grid>
              </>
            ) : coffee ? (
              <>

               <Grid sx={{width:'100%',display:'flex',justifyContent:matches?'center':'space-between',alignItems:'center',flexDirection:matches?"column":'row',gap:matches?6:'',marginTop:'4%'}}>
                <img src={item2} style={{width:matches?'80%':'30%'}}></img>
                <img src={item0} style={{width:matches?'80%':'30%'}}></img>
                <img src={item1} style={{width:matches?'80%':'30%'}}></img>
              </Grid>
              </>
            ) : snack ? (
              <>

<Grid sx={{width:'100%',display:'flex',justifyContent:matches?'center':'space-between',alignItems:'center',flexDirection:matches?"column":'row',gap:matches?6:'',marginTop:'4%'}}>
                <img src={item0} style={{width:matches?'80%':'30%'}}></img>
                <img src={item2} style={{width:matches?'80%':'30%'}}></img>
                <img src={item1} style={{width:matches?'80%':'30%'}}></img>
              </Grid>
              </>
            ) : burgur ? (
              <>


<Grid sx={{width:'100%',display:'flex',justifyContent:matches?'center':'space-between',alignItems:'center',flexDirection:matches?"column":'row',gap:matches?6:'',marginTop:'4%'}}>
                <img src={item2} style={{width:matches?'80%':'30%'}}></img>
                <img src={item0} style={{width:matches?'80%':'30%'}}></img>
                <img src={item1} style={{width:matches?'80%':'30%'}}></img>
              </Grid>

              </>
            ) : chines ? (
              <>

<Grid sx={{width:'100%',display:'flex',justifyContent:matches?'center':'space-between',alignItems:'center',flexDirection:matches?"column":'row',gap:matches?6:'',marginTop:'4%'}}>
                <img src={item1} style={{width:matches?'80%':'30%'}}></img>
                <img src={item2} style={{width:matches?'80%':'30%'}}></img>
                <img src={item2} style={{width:matches?'80%':'30%'}}></img>
              </Grid>
  </>
            ) : south ? (
              <>


<Grid sx={{width:'100%',display:'flex',justifyContent:matches?'center':'space-between',alignItems:'center',flexDirection:matches?"column":'row',gap:matches?6:'',marginTop:'4%'}}>
                <img src={item2} style={{width:matches?'80%':'30%'}}></img>
                <img src={item0} style={{width:matches?'80%':'30%'}}></img>
                <img src={item1} style={{width:matches?'80%':'30%'}}></img>
              </Grid>

              </>
            ) : (
              <></>
            )}
        
            </Grid>

        <Grid sx={{ width: "100%", display: "flex", marginTop:matches?'8%':'4%',gap:4,justifyContent:matches?'center':'',alignItems:'center',flexDirection:matches?"column":'row' }}>
            <Button
              onClick={handleCatygory}
              style={{
                border:'1px solid #2d3436',
                background: category ? "#2d3436" : "",
                fontWeight: category ? 400 : 400,
                color: category ? "#b2bec3" : "#636e72",
                width: "50px",
                height: "35px",
                borderRadius: '15px',
                fontSize: "14px",
                textTransform: "none",
                borderTopLeftRadius: "10px",
                display: "flex",
                lineHeight: "36px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              All
            </Button>
            <Button
              onClick={handleCoffee}
              style={{
                border:'1px solid #2d3436',
                background: coffee ? "#2d3436" : "",
                fontWeight: coffee ? 400 : 400,
                color: coffee ? "#b2bec3" : "#636e72",
                width: "140px",
                height: "35px",
                borderRadius: '15px',
                fontSize: "15px",
                textTransform: "none",
                display: "flex",
                lineHeight: "36px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Vegetables  
            </Button>
            <Button
              onClick={handleSnack}
              style={{
                border:'1px solid #2d3436',
                background: snack ? "#2d3436" : "",
                fontWeight: snack ? 400 : 400,
                color: snack ? "#b2bec3" : "#636e72",
                width: "140px",
                height: "35px",
                borderRadius: '15px',
                fontSize: "14px",
                textTransform: "none",
                display: "flex",
                lineHeight: "36px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Hot Dishes
            </Button>
            <Button
              onClick={handleBurgur}
              style={{
                border:'1px solid #2d3436',
                background: burgur ? "#2d3436" : "",
                fontWeight: burgur ? 400 : 400,
                color: burgur ? "#b2bec3" : "#636e72",
                width: "140px",
                height: "35px",
                fontSize: "14px",
                textTransform: "none",
                display: "flex",
                lineHeight: "36px",
                borderRadius: '15px',
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Cold Dishes
            </Button>
            <Button
              onClick={handleChines}
              style={{
                border:'1px solid #2d3436',
                background: chines ? "#2d3436" : "",
                fontWeight: chines ? 400 : 400,
                color: chines ? "#b2bec3" : "#636e72",
                width: "140px",
                height: "35px",
                fontSize: "14px",
                textTransform: "none",
                borderRadius: '15px',
                display: "flex",
                lineHeight: "36px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
               Fast food
            </Button>

            <Button
              onClick={handleSouth}
              style={{
                border:'1px solid #2d3436',
                background: south ? "#2d3436" : "",
                fontWeight: south ? 400 : 400,
                color: south ? "#b2bec3" : "#636e72",
                width: "120px",
                height: "35px",
                borderRadius: '15px',
                fontSize: "14px",
                textTransform: "none",
                display: "flex",
                lineHeight: "36px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
             Drinks
            </Button>
          
          </Grid>

           </Grid>
          </Grid>




          <div id="chief"></div>


          <Grid sx={{width:'100%',display:'flex',marginTop:'14%',justifyContent:'center',alignItems:'center'}}>
            <Grid sx={{width:'88%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:matches?"column":'row'}}>
             <Grid sx={{width:matches?'100%':'40%'}}>
             <Grid sx={{fontSize:matches?'46px':'52px',fontWeight:700,letterSpacing:'-2.4%'}}>
             OUR CHEF
             </Grid>
             <Grid sx={{ fontSize:'16px',marginTop:'3%',width:'90%',lineHeight:'30px',}}>
             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis id auctor lorem, ac feugiat quam. Donec egestas mollis odio. Proin non pretium ligula, a finibus dolor.
            </Grid>

            <Button  className={classes.button1}
                sx={{
                 border:'1px solid #7f8c8d',
                  height: 42,
                  width:matches?'250px':'15vw',
                  color: "#7f8c8d",
                  fontWeight: "bold",
                  justifyContent: "center",
                  textTransform: "none",
                  padding: 2.6,
                  display: "flex",
                  marginTop:matches?'4%':'8%',
                  borderRadius: 20,
                  fontSize: "14px",
                  lineHeight:'22px',
                  fontWeight: 500,
                  alignItems:'center',
                }}
              >
              RESERVE TABLE
              </Button>
            </Grid>

            <Grid sx={{width:matches?'100%':'60%',display:'flex',justifyContent:'space-between',gap:matches?2:4,marginTop:matches?'8%':''}}>
               <Grid sx={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                  <Grid >
                    <img src={safe2} style={{width:'100%'}}></img>
                  </Grid>
                  <Grid sx={{fontSize:matches?'16px':'22px',fontWeight:500,marginTop:'3%'}}>
                   Riya Sharma
                  </Grid>
                  <Grid sx={{marginTop:"3%",width:matches?'50%':'30%'}}>
                 <Divider
            style={{
              backgroundColor:"#7f8c8d",
            }}
          />
                 </Grid>
                  <Grid sx={{fontSize:matches?'12px':'14px',fontWeight:400,color:'#b2bec3',marginTop:'3%'}}>
                   The master chef
                  </Grid>
               </Grid>
               <Grid sx={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                  <Grid>
                    <img src={safe} style={{width:'100%'}}></img>
                  </Grid>
                  <Grid sx={{fontSize:matches?'16px':'22px',fontWeight:500,marginTop:'3%'}}>
                   Shivani Tomar
                  </Grid>
                  <Grid sx={{marginTop:"3%",width:matches?'50%':'30%'}}>
                 <Divider
            style={{
              backgroundColor:"#7f8c8d",
            }}
          />
                 </Grid>
                  <Grid sx={{fontSize:matches?'12px':'14px',fontWeight:400,color:'#b2bec3',marginTop:'3%'}}>
                 The master chef
                  </Grid>
               </Grid>
               <Grid sx={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                  <Grid >
                    <img src={safe1} style={{width:'100%'}}></img>
                  </Grid>
                  <Grid sx={{fontSize:matches?'16px':'22px',fontWeight:500,marginTop:'3%'}}>
                   Riya Sharma
                  </Grid>
                  <Grid sx={{marginTop:"3%",width:matches?'50%':'30%'}}>
                 <Divider
            style={{
              backgroundColor:"#7f8c8d",
            }}
          />
                 </Grid>
                  <Grid sx={{fontSize:matches?'12px':'14px',fontWeight:400,color:'#b2bec3',marginTop:'3%'}}>
                   The chef
                  </Grid>
               </Grid>
            </Grid>
            </Grid>
</Grid>














<Grid sx={{width:'100%',display:'flex',marginTop:'14%',justifyContent:'center',alignItems:'center'}}>
            <Grid sx={{width:'70%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
             <Grid sx={{fontSize:matches?'46px':'52px',fontWeight:700,letterSpacing:'-2.4%'}}>
             SERVICES
             </Grid>
             <Grid sx={{ fontSize:'16px',marginTop:'1.6%',width:matches?'100%':'45%',lineHeight:'26px',textAlign:'center',}}>
             Aenean aliquam ultricies turpis, vel facilisis nisi lacinia non. Suspendisse magna arcu, viverra in vehicula non, dapibus sed er.
            </Grid>



            <Grid sx={{width:'100%',display:'flex',justifyContent:matches?"center":'space-between',gap:8,marginTop:matches?'12%':'4%',flexDirection:matches?"column":'row'}}>
               <Grid sx={{display:'flex',alignItems:'center',flexDirection:'column'}}>
               <Grid sx={{border:'8px solid #fff',padding:2,width:'50%'}}>
                  <Grid>
                    <img src={foods} style={{width:'100%'}}></img>
                  </Grid>
                </Grid>
                  <Grid sx={{fontSize:'22px',fontWeight:500,marginTop:'4%'}}>
                   Food
                  </Grid>
                  <Grid sx={{marginTop:"3%",width:'30%'}}>
                 <Divider
            style={{
              backgroundColor:"#7f8c8d",
            }}
          />
                 </Grid>
                  <Grid sx={{fontSize:'14px',fontWeight:400,color:'#b2bec3',marginTop:'3%',lineHeight:'28px',textAlign:'center'}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ullamcorper, orci eget condimentum sodales, est purus dapibus orci, a rhoncus tellus est id lacus.
                  </Grid>
               </Grid>
               <Grid sx={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                <Grid sx={{border:'8px solid #fff',padding:2,width:'50%'}}>
                  <Grid>
                    <img src={dish} style={{width:'100%'}}></img>
                  </Grid>
                </Grid>
                  <Grid sx={{fontSize:'22px',fontWeight:500,marginTop:'4%'}}>
                   Dishes
                  </Grid>
                  <Grid sx={{marginTop:"3%",width:'30%'}}>
                 <Divider
            style={{
              backgroundColor:"#7f8c8d",
            }}
          />
                 </Grid>
                 <Grid sx={{fontSize:'14px',fontWeight:400,color:'#b2bec3',marginTop:'3%',lineHeight:'28px',textAlign:'center'}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ullamcorper, orci eget condimentum sodales, est purus dapibus orci, a rhoncus tellus est id lacus.
                  </Grid>
               </Grid>
               <Grid sx={{display:'flex',alignItems:'center',flexDirection:'column'}}>
               <Grid sx={{border:'8px solid #fff',padding:2.5,width:'50%'}}>
                  <Grid>
                    <img src={Birth} style={{width:'100%'}}></img>
                  </Grid>
                </Grid>
                  <Grid sx={{fontSize:'22px',fontWeight:500,marginTop:'4%'}}>
                   Birthdays
                  </Grid>
                  <Grid sx={{marginTop:"3%",width:'30%'}}>
                 <Divider
            style={{
              backgroundColor:"#7f8c8d",
            }}
          />
                 </Grid>
                 <Grid sx={{fontSize:'14px',fontWeight:400,color:'#b2bec3',marginTop:'3%',lineHeight:'28px',textAlign:'center'}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ullamcorper, orci eget condimentum sodales, est purus dapibus orci, a rhoncus tellus est id lacus.
                  </Grid>
               </Grid>
            </Grid>
            </Grid>
            </Grid>


           
            <Grid sx={{width:'100%',display:'flex',marginTop:'8%',justifyContent:'center',alignItems:'center'}}>
            <Grid sx={{width:'70%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
            <div id="item"></div>
             <Grid sx={{fontSize:matches?'46px':'52px',fontWeight:700,letterSpacing:'-2.4%',marginTop:'2%'}}>
             OUR ITEM
             </Grid>
             <Grid sx={{ fontSize:'16px',marginTop:'1.6%',width:matches?'100%':'60%',lineHeight:'26px',textAlign:'center',}}>
             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vel placerat lorem, et lacinia sapien. Sed nec sodales felis. Fusce tincidunt magna nibh, ac maximus eros vulputate non. Duis nec lorem mi.
            </Grid>
          
</Grid>
</Grid>








    </Grid>)
}