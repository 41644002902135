import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
 
  button: {
   color: '#fff', // Default color
    '&:hover': {
      color : '#bdc3c7', // Color when hovered
    },
  },


  button1: {
    border:'1px solid #7f8c8d',
    color: "#7f8c8d",
     '&:hover': {
        border:'1px solid #fff',
    color: "#fff",
     }, 
   },

   button2: {
    background: '#fff', // Default color
     '&:hover': {
      background : '#09cc7f', // Color when hovered
     },
     
   },


}));


export {useStyles}