import { Grid } from "@mui/material";
import MainHeader from "../MainHeader";
import Container from "../Container";
import SliderForReview from "../SliderForItem";
import Review from "../Review";
import Footer from "../Footer";

export default function Home()
{

 return(
    <Grid sx={{background:'#000',height:'auto'}}>
        <Grid>
            <Container/>
        </Grid>
        <Grid>
        <SliderForReview/>
        </Grid>
        <Grid >
        <Review/>
        </Grid>
        <Grid>
            <Footer />
        </Grid>
    </Grid>
 )
}